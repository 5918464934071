import React from "react";
import ReactDOM from "react-dom";
import "./CSS/index.css";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-responsive-carousel/lib/styles/carousel.min.css";


ReactDOM.render(<Routes />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
